(function () {
	'use strict';

	angular.module('app').factory('manageMap', [manageMap]);

	function manageMap() {
		let promiseResolver = {};
		let promiseRejector;
		const data = {};
		let apiKeyLoaded;

		return {
			initialize: initialize,
			getAsync: getAsync,
			get: get,
			set: set,
		};

		/* @type {options: {apiKey: string} => Promise} */
		function initialize(options) {
			if (apiKeyLoaded !== options.apiKey) {
				/* prettier-ignore */
				(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
		({key: options.apiKey, v: "weekly"});
				apiKeyLoaded = options.apiKey;
			}
			return google;
		}

		function getAsync(key) {
			return new Promise((resolve, reject) => {
				promiseResolver[key] = resolve;
				// if the property is for options don't wait to resolve as this may not exist at all
				if (data[key] || key === 'options') {
					resolve(data[key]);
				}
			});
		}

		function get(key) {
			return data?.[key];
		}

		function set(key, value) {
			data[key] = value;
			if (promiseResolver[key]) {
				promiseResolver[key](data[value]);
			}
		}
	}
})();
